import { defineKey as T } from '@ha/intl';

import { UtilityBills, UtilityBillsValues } from 'ha/models/Listing/types';

export const BANNER_CARD_IMAGE_SIZE = {
  lg: { width: 270, height: 180 },
  sm: {
    width: 150,
    height: 100,
  },
};

export const SEARCH_IMGIX_PATH = 'pages/search';

export const BANNERS_LIST = {
  // Mapped banner to the topicCluster
  livingIn: 'livingIn',
  studying: 'studying',
  movingAbroad: 'moving-abroad',
  working: 'working',
  rentingAbroad: 'renting-abroad',

  // Default banner when we don't have any banner for the topicCluster
  default: 'default',

  // This banner is for placeholder card (When we have no content card to render)
  placeholderCard: 'no-banner',
};

export const ListingCardBillsLabelTranslations: Record<UtilityBills, string> = {
  [UtilityBillsValues.INCLUDED]: T('search.listing_card.bills_included'),
  [UtilityBillsValues.EXCLUDED]: T('search.listing_card.bills_excluded'),
  [UtilityBillsValues.PARTIALLY]: T('search.listing_card.some_bills_included'),
};
